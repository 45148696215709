// DONT DELETE COMMENTED CODE
// it contains animation functionality that might be helpful in future

/**
 * commented code functionality
 *
 * the animation runs between:
 *      `max-height: 100px`(collapsedHeight) and `max-height: 200rem`(expandedHeight).
 * `200rem` is used so as to provide a much higher value than the content will ever have.
 * This is done because we cant use `max-height: unset` in animations.
 *
 * we cant ensure the max-height will never reach 200rem as the content is dynamic,
 * so we cant use this approach.
 *
 * so as a hack, we tried to use the initial height of the content and pass that value
 * in animation keyframes using inline styles. But that part is yet to be figured out
 *
 * extra: to avoid collapse animation as soon as the component is rendered, `startAnimations` variable is maintained
 * which is set as true on click of a button, so that animations only begin after that.
 */

import { classNames } from "repoV2/utils/common/render/classNames";
import React, { useEffect, useRef, useState } from "react";
import { BsCaretDownFill as DownIcon } from "@react-icons/all-files/bs/BsCaretDownFill";
import { BsCaretUpFill as UpIcon } from "@react-icons/all-files/bs/BsCaretUpFill";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { IReadMoreWrapper } from "./IReadMoreWrapper";
import styles from "./readMoreWrapper.module.scss";

/**
 * TODO: make it using MUI Collapse https://mui.com/material-ui/api/collapse/
 */
/**
 * @deprecated use `repoV2/components/ReadMoreWrapper/index.tsx`
 */
export const ReadMoreWrapper = ({
    id,
    children,
    onlyExpand,
    showReadMoreFunctionality: showReadMore = true,
    collapsedHeight,
    clickCallback,
    ReadMoreButton: ReadMoreButtonProp,
    ReadLessButton: ReadLessButtonProp,
    className,
    buttonClassName,
}: IReadMoreWrapper) => {
    // const [startAnimations, setStartAnimations] = useState(false);
    // const [maxHeight, setMaxHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [showReadMoreFunctionality, setShowReadMoreFunctionality] =
        useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (showReadMore) {
            const elementHeight =
                document.querySelector(`#${id}>div`)?.clientHeight || 0;
            // setShowReadMoreFunctionality(elementHeight > 120);
            setShowReadMoreFunctionality(elementHeight > collapsedHeight);
            // setMaxHeight(elementHeight);
        }
    }, [children, collapsedHeight]);

    let style = {};

    if (showReadMoreFunctionality) {
        style = { overflow: "hidden" };
        if (expanded) {
            style = { ...style, maxHeight: "unset" };
        } else {
            style = { ...style, maxHeight: collapsedHeight };
        }
    }

    const onReadMoreClick = () => {
        // setStartAnimations(true);
        setExpanded(true);
        clickCallback?.(expanded);
    };

    const ReadMoreButton = () => {
        if (ReadMoreButtonProp) {
            return <ReadMoreButtonProp onClick={onReadMoreClick} />;
        }
        return (
            <ButtonV2
                design="plain"
                applyTheme
                className={classNames(
                    styles.button,
                    styles.readMore,
                    buttonClassName
                )}
                onClick={onReadMoreClick}
            >
                Read More
                <DownIcon className={styles.icon} />
            </ButtonV2>
        );
    };

    const onReadLessClick = () => {
        // setStartAnimations(true);
        setExpanded(false);
        clickCallback?.(expanded);
    };

    const ReadLessButton = () => {
        if (ReadLessButtonProp) {
            return <ReadLessButtonProp onClick={onReadLessClick} />;
        }
        return (
            <ButtonV2
                design="plain"
                applyTheme
                className={classNames(
                    styles.button,
                    styles.readLess,
                    buttonClassName
                )}
                onClick={onReadLessClick}
            >
                Read Less
                <UpIcon className={styles.icon} />
            </ButtonV2>
        );
    };

    return (
        <>
            <div
                ref={ref}
                id={id}
                className={classNames(className)}
                // className={classNames(
                //     showReadMoreFunctionality && styles.readMoreFunctionality,
                //     showReadMoreFunctionality &&
                //         !startAnimations &&
                //         styles.initialState,
                //     showReadMoreFunctionality &&
                //         startAnimations &&
                //         (expanded ? styles.expanded : styles.collapsed)
                // )}
                style={style}
            >
                {children}
            </div>
            {showReadMoreFunctionality && !expanded ? <ReadMoreButton /> : null}
            {showReadMoreFunctionality && expanded && !onlyExpand ? (
                <ReadLessButton />
            ) : null}
        </>
    );
};
