import {
    ApiCall,
    API_ACTIONS,
    API_ACTION_TYPES,
    getItemCountFromResponsive,
    isEmpty,
    SELECTORS,
} from "@Utils";
import { logError } from "repoV2/utils/common/error/error";
import React from "react";
import MultiCarousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { useIsDesktop } from "repoV2/utils/common/render/screen";
import { awardResponsive, CREATOR_MISCELLANEOUS_DATA_TYPES } from "../data";
import { ICreatorMiscellaneousData } from "../ICreatorMiscellaneousData";
import module_style from "../Style.module.scss";

export const AwardsList = ({ titleClass }: { titleClass: string }) => {
    const hostName = useSelector(SELECTORS.hostName)!;

    const [awards, setAwards] = React.useState<
        ICreatorMiscellaneousData.IProps["awards"] | undefined
    >(undefined);
    const [showMore, setShowMore] = React.useState<boolean>(false);

    // Whether display viewport is desktop
    const isDesktop = useIsDesktop();
    const itemCount = getItemCountFromResponsive(awardResponsive);

    const listData = awards?.data || [];
    const itemExceeded = listData && listData?.length > itemCount;
    const carouselContainerClass = itemExceeded ? "" : "justify-content-center";

    const filteredlistData =
        !isDesktop && showMore
            ? listData.filter((_, index) => index + 1 > itemCount)
            : listData;

    const showMoreCheck = !isDesktop && listData && listData.length > itemCount;

    const handleShowMore = () => setShowMore(!showMore);

    React.useEffect(() => {
        (async () => {
            try {
                const responseData: any = await ApiCall({
                    url: API_ACTIONS[
                        API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA
                    ].url({
                        hostName,
                        type: CREATOR_MISCELLANEOUS_DATA_TYPES.AWARDS_CERTIFICATES,
                    }),
                });
                if (responseData.status === 200) {
                    const awardsData =
                        responseData?.data?.data?.miscellaneous_data.find(
                            (item: any) =>
                                item.type ===
                                CREATOR_MISCELLANEOUS_DATA_TYPES.AWARDS_CERTIFICATES
                        );

                    setAwards({
                        section_name: awardsData?.data?.section_name,
                        data: awardsData?.data?.data,
                    });
                }
            } catch (e) {
                logError({
                    error: e,
                    occuredAt: "AwardsList",
                    when: "calling API_ACTION_TYPES.FETCH_CREATOR_MISCELLANEOUS_DATA",
                    severity: "medium",
                });
            }
        })();
    }, []);

    const renderItem = (
        item: ICreatorMiscellaneousData.IProps["awards"]["data"][0]
    ) => (
        <div
            key={`award_${item?.id}`}
            className={`d-flex flex-column align-items-center m-2 col-12 `}
        >
            <img
                className={`${module_style.award_image}`}
                src="/static/img/trustmarker/award.svg"
                alt="award_default"
            />
            <div className={`mt-2 text-center ${module_style.award_title}`}>
                {item.award_name}
            </div>
            <div
                className={`mt-2 text-center ${module_style.award_description}`}
            >
                {item.organisation_name}, {item.year}
            </div>
        </div>
    );

    if (isEmpty(listData)) return <></>;

    return (
        <div
            className={`${module_style.conatiner} mb-4 d-flex flex-column align-items-center w-100 ${module_style.templateColor}`}
        >
            <div className={`${module_style.section_name} ${titleClass}`}>
                {awards?.section_name}
            </div>

            {isDesktop ? (
                <div
                    className={`${module_style.galleryOuterDiv} ${module_style.awardWidth}`}
                >
                    <MultiCarousel
                        responsive={awardResponsive}
                        ssr
                        containerClass={carouselContainerClass}
                        arrows={false}
                        autoPlay
                        infinite={itemExceeded}
                    >
                        {listData.map(item => (
                            <div key={`brand_${item?.id}`}>
                                {renderItem(item)}
                            </div>
                        ))}
                    </MultiCarousel>
                </div>
            ) : (
                <div
                    className={`row justify-content-around ${module_style.list_container}`}
                >
                    {filteredlistData.map(item => {
                        return <>{renderItem(item)}</>;
                    })}
                </div>
            )}

            {showMoreCheck && (
                <div
                    onClick={handleShowMore}
                    className={`${module_style.award_description} mt-4 pointer`}
                >
                    {showMore ? "Show less" : "and many more"}
                </div>
            )}
        </div>
    );
};
