import { CREATOR_MISCELLANEOUS_DATA_TYPES as CREATOR_MISCELLANEOUS_DATA_TYPES_BASE } from "repoV2/features/CreatorPages/constants/CreatorPages.constants";

/**
 * @deprecated `use from repoV2/features/CreatorPages/constants/CreatorPages.constants`
 */
export const CREATOR_MISCELLANEOUS_DATA_TYPES =
    CREATOR_MISCELLANEOUS_DATA_TYPES_BASE;

export const awardResponsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1440 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1439, min: 769 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 3,
    },
};

export const mediaResponsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1440 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1439, min: 769 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};

export const brandResponsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1440 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1439, min: 769 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 3,
    },
};
