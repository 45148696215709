/* eslint-disable react/require-default-props */
import { ITemplate } from "@Templates/ITemplate";
import { getSocialData, useSocialIconClick } from "@Utils";
import React from "react";
import { numberToShortString } from "repoV2/utils/common/dataTypes/number";
import styles from "./style.module.scss";

export const FollowerCountIcons = ({
    containerClass,
    itemClass,
    socialLinks,
    hideSocialIcons,
    hideSocialCount = false,
    socialIconCustomHandler,
}: {
    containerClass?: string;
    itemClass?: string;
    socialLinks: ITemplate.ISocialIconsProps["socialLinks"];
    hideSocialIcons?: boolean;
    hideSocialCount?: boolean;
    socialIconCustomHandler?: (socialObj: any) => string;
}) => {
    const { onSocialIconClick } = useSocialIconClick();
    if (hideSocialIcons) return null;

    return (
        <>
            {socialLinks && (
                <div
                    className={`d-flex mb-4 mt-4 align-items-start ${containerClass} flex-wrap `}
                >
                    {Object.entries(socialLinks).map(
                        ([socialKey, socialObject]) => {
                            const value = getSocialData(socialKey);
                            return (
                                <div
                                    className={`mb-4 mr-3 d-flex flex-column align-items-center ${styles.item}  ${styles.templateColor} ${itemClass} pointer justify-content-center`}
                                    key={socialKey}
                                    onClick={() =>
                                        onSocialIconClick(socialObject?.link)
                                    }
                                >
                                    <img
                                        className={styles.followerCountImage}
                                        src={
                                            typeof socialIconCustomHandler ===
                                            "function"
                                                ? socialIconCustomHandler(value)
                                                : value?.icon
                                        }
                                        alt={socialKey}
                                        loading="lazy"
                                    />
                                    {socialObject?.followerCount &&
                                    socialObject?.followerCount > 0 &&
                                    !hideSocialCount ? (
                                        <>
                                            <div
                                                className={`${styles.followerCountTitle} mt-1`}
                                            >
                                                {numberToShortString(
                                                    socialObject?.followerCount
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    styles.followerCountDescription
                                                }
                                            >
                                                {value?.subtitle}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            );
                        }
                    )}
                </div>
            )}
        </>
    );
};

FollowerCountIcons.defaultProps = {
    containerClass: "",
    itemClass: "",
};
