import {
    TABLET_SCREEN_WIDTH as _TABLET_SCREEN_WIDTH,
    MOBILE_SCREEN_WIDTH as _MOBILE_SCREEN_WIDTH,
    // @ts-ignore
} from "@my-scoot/exly-react-component-lib/build/cjs/constants/common/screen";
import {
    TABLET_SCREEN_WIDTH as TABLET_SCREEN_WIDTH_TYPE,
    MOBILE_SCREEN_WIDTH as MOBILE_SCREEN_WIDTH_TYPE,
} from "@my-scoot/exly-react-component-lib/build/types/constants/common/screen";

export const MOBILE_SCREEN_WIDTH: typeof MOBILE_SCREEN_WIDTH_TYPE =
    _MOBILE_SCREEN_WIDTH;

export const TABLET_SCREEN_WIDTH: typeof TABLET_SCREEN_WIDTH_TYPE =
    _TABLET_SCREEN_WIDTH;

export const MAX_MEDIUM_DEVICE_WIDTH = 1366;

// Carousal breakpoints for large medium and small screen sizes
export const CAROUSAL_BREAKPOINTS = {
    large: {
        breakpoint: { max: 4000, min: 1081 },
        items: 1,
    },
    medium: {
        breakpoint: { max: 1080, min: 681 },
        items: 1,
    },
    small: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
    },
};
