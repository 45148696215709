import {
    useIsDesktop as _useIsDesktop,
    useIsTablet as _useIsTablet,
    useScreenSize as _useScreenSize,
    // @ts-ignore
} from "@my-scoot/exly-react-component-lib/build/cjs/utils/common/screen";
import {
    useIsDesktop as useIsDesktopType,
    useIsTablet as useIsTabletType,
    useScreenSize as useScreenSizeType,
} from "@my-scoot/exly-react-component-lib/build/types/utils/common/screen";
import {
    MAX_MEDIUM_DEVICE_WIDTH,
    MOBILE_SCREEN_WIDTH,
} from "repoV2/constants/screen";

type IUseScreenSize = typeof useScreenSizeType;
export const useScreenSize: IUseScreenSize = _useScreenSize;

type IUseIsDesktop = typeof useIsDesktopType;
export const useIsDesktop: IUseIsDesktop = _useIsDesktop;

type IUseIsTablet = typeof useIsTabletType;
export const useIsTablet: IUseIsTablet = _useIsTablet;

/**
 * @deprecated Use useIsDesktop
 */
export const useIsMobile = () => {
    const { width } = useScreenSize();
    return width <= MOBILE_SCREEN_WIDTH;
};

/**
 * @deprecated this is feature specific not generic
 * TODO: should be in feature file
 */
export const useIsMediumScreen = () => {
    const { width } = useScreenSize();
    return width > MOBILE_SCREEN_WIDTH && width < MAX_MEDIUM_DEVICE_WIDTH;
};
