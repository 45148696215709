/**
 * eg: 10000 => 10K
 */
export const numberToShortString = (value: number) => {
    const suffixes = ["", "K", "M", "B", "T"];
    const suffixNum = Math.floor(`${value}`.length / 3);
    const shortValue = parseFloat(
        (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(2)
    );
    return suffixNum < suffixes.length - 1
        ? `${shortValue % 1 !== 0 ? shortValue.toFixed(1) : shortValue}${
              suffixes[suffixNum]
          }`
        : `${shortValue % 1 !== 0 ? shortValue.toFixed(1) : shortValue}${
              suffixes[suffixes.length - 1]
          }+`;
};

/**
 *
 * @param value number
 * @returns ordinal suffix
 */
export const getOrdinalSuffix = (value: number) => {
    const suffixIndex =
        (value > 3 && value < 21) || value % 10 > 3 ? 0 : value % 10;
    return value > 0 ? ["th", "st", "nd", "rd"][suffixIndex] : "";
};
